import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useEffect } from "react";

const AuthCheck = ({ children }) => {
  const router = useRouter();

  const { is_logged_in, last_sign_in } = useSelector((state) => state.auth);

  useEffect(() => {
    if (router.isReady) {
      if (!is_logged_in|| last_sign_in == null) {
        router.push("/auth/login");
      }
    }
  }, [is_logged_in, router.isReady]);

  // Only render the child components if the user is logged in
  if (is_logged_in) {
    return children;
  }

  // Otherwise return nothing
  return null;
};

export default AuthCheck;
